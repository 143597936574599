import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Greeting from "home/Greeting";
import SplashScreen from "initialization/SplashScreen";
import AppRoutes from "common/AppRoutes";
import { InitializationController } from "initialization/Initialization";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <InitializationController />
        <Routes>
          <Route path={AppRoutes.ROOT} element={<SplashScreen />} />
          <Route path={AppRoutes.GREETING} element={<Greeting />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
