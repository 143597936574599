import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum INITIALIZATION_STEP {
  NOT_STARTED = 0,
  SETUP_COMPLETE,
}

interface InitializationState {
  currentStep: INITIALIZATION_STEP;
}

const initialState: InitializationState = {
  currentStep: INITIALIZATION_STEP.NOT_STARTED,
};

const initializationSlice = createSlice({
  name: "initialization",
  initialState,
  reducers: {
    setCurrentInitializationStep: (
      state,
      action: PayloadAction<INITIALIZATION_STEP>
    ) => {
      state.currentStep = action.payload;
    },
  },
});

export const { setCurrentInitializationStep } = initializationSlice.actions;
export default initializationSlice.reducer;
