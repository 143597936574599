/**
 * Defines routes used in the app.
 */
enum AppRoutes {
  /** The root path is only used for the initial setup flow. */
  ROOT = "/",
  GREETING = "/greeting",
}

export default AppRoutes;
