import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppConfig {
  environment?: string;
  serverHost?: string;
}

const initialState: AppConfig = {};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setAppConfig: (state, action: PayloadAction<AppConfig>) => {
      state.environment = action.payload.environment;
      state.serverHost = action.payload.serverHost;
    },
  },
});

export const { setAppConfig } = appConfigSlice.actions;
export default appConfigSlice.reducer;
