import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AppRoutes from "common/AppRoutes";
import {
  setCurrentInitializationStep,
  INITIALIZATION_STEP,
} from "./InitializationSlice";
import appConfigManager from "common/AppConfigManager";
import { setAppConfig } from "common/AppConfigSlice";

export const InitializationController: React.FC = () => {
  const currentInitializatonStep = useAppSelector(
    (state) => state.initialization.currentStep
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    switch (currentInitializatonStep) {
      case INITIALIZATION_STEP.NOT_STARTED:
        appConfigManager.loadAppConfig().then((appConfig) => {
          if (appConfig) {
            dispatch(setAppConfig(appConfig));
            dispatch(
              setCurrentInitializationStep(INITIALIZATION_STEP.SETUP_COMPLETE)
            );
          }
        });
        break;

      case INITIALIZATION_STEP.SETUP_COMPLETE:
        navigate(AppRoutes.GREETING);
        break;

      default:
        break;
    }
  }, [currentInitializatonStep, navigate, dispatch]);

  return <></>;
};
