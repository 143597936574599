import { ThemeProvider } from "@emotion/react";
import { Button, ButtonProps, createTheme, styled } from "@mui/material";
import {
  color_default,
  color_paper,
  color_primary_key,
  color_secondary_surface,
} from "../styles/Colors";

const StandardButton = styled(Button)(() => ({
  padding: "0px 24px",
  textTransform: "none",
  height: 56,
  fontSize: 21,
}));

const PrimaryTheme = createTheme({
  palette: {
    primary: {
      main: color_primary_key,
      dark: color_primary_key,
      contrastText: color_paper,
    },
    secondary: {
      main: "#F2F4F4",
      contrastText: "#545555",
    },
  },
});

const LightPrimaryTheme = createTheme({
  palette: {
    primary: {
      main: color_secondary_surface,
      contrastText: color_default,
      dark: color_secondary_surface,
    },
  },
});

interface StyledButtonProps extends ButtonProps {
  makeLight?: boolean;
}

type BUTTON = React.FC<StyledButtonProps>;

const StyledButton: BUTTON = (props) => {
  const { makeLight, ...reducedProps } = props;
  return (
    <ThemeProvider theme={makeLight ? LightPrimaryTheme : PrimaryTheme}>
      <StandardButton variant="contained" disableElevation {...reducedProps} />
    </ThemeProvider>
  );
};

export default StyledButton;
