import {
  GreetingRequest,
  GreetingResponse,
} from "../generated/com/verily/example/greeting/GreetingService_pb";
import { GreetingServiceClient } from "../generated/com/verily/example/greeting/GreetingService_grpc_web_pb";
import { RpcError, Metadata } from "grpc-web";
import appConfigManager from "common/AppConfigManager";

export type GetGreetingOnSuccessListerner = (message: string) => void;

export type OnFailureListener = (error: RpcError) => void;

class GreetingManager {
  createGreetingClient() {
    return new GreetingServiceClient(
      appConfigManager.appConfig!!.serverHost!!,
      null,
      null
    );
  }

  /** Set time out of gRpc calls to 10 seconds */
  getGrpcMetaData(): Metadata {
    return {
      deadline: (new Date().getTime() + 10000).toString(),
    };
  }

  getGreetingMessage(
    userId: number,
    onSuccess: GetGreetingOnSuccessListerner,
    onFailure: OnFailureListener
  ) {
    const client = this.createGreetingClient();
    const request = new GreetingRequest().setId(userId);
    client.greet(
      request,
      this.getGrpcMetaData(),
      (err: RpcError, response: GreetingResponse) => {
        if (err) {
          console.log(err);
          onFailure(err);
        } else {
          onSuccess(response.getGreeting());
        }
      }
    );
  }
}

const greetingManager = new GreetingManager();
export default greetingManager;
