import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as VerilyIcon } from "assets/verily-icon.svg";
import { color_muted, color_primary_key } from "styles/Colors";
import CircularProgress from "@mui/material/CircularProgress";

const SplashScreen: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <VerilyIcon />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        <CircularProgress
          size={16}
          sx={{ marginRight: "16px", color: color_primary_key }}
        />
        <Typography variant="subtitle1" sx={{ color: color_muted }}>
          Initializing ...
        </Typography>
      </Box>
    </Box>
  );
};

export default SplashScreen;
