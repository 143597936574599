import { AppConfig } from "./AppConfigSlice";

/**
 * Stores the information loaded from app.config.json file.
 */
class AppConfigManager {
  appConfig?: AppConfig;

  loadAppConfig(): Promise<AppConfig | undefined> {
    return new Promise<AppConfig | undefined>((resolve) => {
      fetch("config/app.config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.appConfig = {
            environment: jsonData.environment,
            serverHost: jsonData.serverHost,
          };
          resolve(this.appConfig);
        })
        .catch((error) => {
          console.log("error = " + error);
          resolve(undefined);
        });
    });
  }
}

const appConfigManager = new AppConfigManager();

export default appConfigManager;
