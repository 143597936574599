import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import StyledButton from "../components/StyledButton";
import greetingManager from "./GreetingManager";
import { useAppSelector } from "redux/hooks";

const Greeting: React.FC = () => {
  const appConfig = useAppSelector((state) => state.appConfig);

  const [userId, setUserId] = useState("1");
  const [greetingMessage, setGreetingMessage] = useState("");

  const handleClickGreetButton = () => {
    greetingManager.getGreetingMessage(
      +userId, // convert userId from string to integer.
      (message) => {
        setGreetingMessage(message);
      },
      (error) => {
        setGreetingMessage(error.toString());
      }
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginTop: "100px",
        }}
      >
        Enter user id:
      </Typography>
      <TextField
        id="userid"
        variant="outlined"
        type="number"
        inputProps={{ style: { fontSize: "21px" } }}
        value={userId}
        onChange={(event) => {
          setUserId(event.target.value);
        }}
        sx={{ width: "200px", marginTop: "16px", marginBottom: "24px" }}
      />
      <StyledButton makeLight onClick={handleClickGreetButton}>
        Greeting
      </StyledButton>
      <Typography
        variant="subtitle1"
        sx={{
          marginTop: "24px",
        }}
      >
        {greetingMessage}
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          marginTop: "24px",
        }}
      >
        Environment: {appConfig.environment}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          marginTop: "8px",
        }}
      >
        Server host: {appConfig.serverHost}
      </Typography>

      <Box sx={{ marginTop: "24px" }}>
        <iframe
          title="embeded looker widget"
          src="https://verilystudy.cloud.looker.com/embed/looks/5?allow_login_screen=true"
          width="600"
          height="400"
          frameBorder="0"
        ></iframe>
      </Box>
    </Box>
  );
};

export default Greeting;
