const color_mdc_theme_primary = "#0063dd";
const color_mdc_theme_secondary = "#870186";
const color_background = "#F2F4F4";
const color_paper = "#FFFFFF";
const color_divider = "#DCE0E0";
const color_form_outlines = "#97A1A1";
const color_dark = "#2B3232";
const color_default = "#556868";
const color_muted = "#8B9A9A";
const color_button_text = "#545555";
const color_text_grey = "#757575";
const color_primary_key = "#5A978C";
const color_primary_surface = "#E6EFEE";
const color_primary_divider = "#C5DBD7";
const color_secondary_key = "#8EA4A0";
const color_secondary_surface = "#EEF1F1";
const color_secondary_divider = "#D7DFDE";
const color_error = "#DF9959";
const color_error_surface = "#FAE7E7";
const color_error_divider = "#F2C6C6";
const color_success = "#73BA82";
const color_success_surface = "#ECFAEF";
const color_success_divider = "#D2F2D9";

export {
  color_mdc_theme_primary,
  color_mdc_theme_secondary,
  color_background,
  color_paper,
  color_divider,
  color_form_outlines,
  color_dark,
  color_default,
  color_muted,
  color_button_text,
  color_text_grey,
  color_primary_key,
  color_primary_surface,
  color_primary_divider,
  color_secondary_key,
  color_secondary_surface,
  color_secondary_divider,
  color_error,
  color_error_surface,
  color_error_divider,
  color_success,
  color_success_surface,
  color_success_divider,
};
