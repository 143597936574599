import { configureStore } from "@reduxjs/toolkit";
import appConfigReducer from "common/AppConfigSlice";
import initializationReducer from "initialization/InitializationSlice";

export const store = configureStore({
  reducer: {
    appConfig: appConfigReducer,
    initialization: initializationReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
